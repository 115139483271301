<script setup lang="ts">
import { ref } from 'vue';

import { IDialog } from '@mfl/common-components/src';
import {
  WsDialogForm,
  WsDialogSection,
  WsInput,
  WsDialogActions,
  WsButton,
} from '@mfl/common-components';
import {
  campaignGateway,
  ThinCampaign,
  UpdateRequest,
  UpdateResponse,
} from '@msl/campaign-gateway-sdk';

import strings from '../campaigns.strings';

const { dialog } = defineProps<{
  dialog: IDialog<
    ThinCampaign | UpdateRequest,
    UpdateResponse & { title: string }
  >;
}>();

const busy = ref(false);
const campaignTitle = ref<string>(dialog.input.title || '');
const error = ref<string>('');

async function save() {
  if (campaignTitle.value.trim() === '') return;

  busy.value = true;

  try {
    const { success } = await campaignGateway[
      dialog.input.campaignId ? 'update' : 'create'
    ]({
      ...dialog.input,
      title: campaignTitle.value.trim(),
    });

    if (!success) {
      error.value = strings.saveFailed;
      return;
    }

    dialog.close({ success, title: campaignTitle.value.trim() });
  } catch {
    error.value = strings.saveFailed;
  } finally {
    busy.value = false;
  }
}

function closeMe() {
  dialog.close();
}
</script>

<template>
  <WsDialogForm>
    <WsDialogSection class="rename-campaign-dialog-section">
      <WsInput
        v-model="campaignTitle"
        :placeholder="strings.campaignNameModalInputPlaceholder"
        aid="RENAME_CAMPAIGN_INPUT"
        size="md"
        :error="error"
        autofocus
      />
    </WsDialogSection>
    <WsDialogActions>
      <WsButton
        aid="RENAME_CAMPAIGN_CANCEL"
        variant="outlined"
        color="gray-500"
        @click="closeMe"
      >
        {{ strings.cancel }}
      </WsButton>
      <WsButton aid="RENAME_CAMPAIGN_SAVE" :loading="busy" @click="save">
        {{ strings.save }}
      </WsButton>
    </WsDialogActions>
  </WsDialogForm>
</template>

<style lang="scss">
.rename-campaign-dialog-section {
  width: 400px;
}
</style>
